import React from "react";
import MasterDetail from "../components/MasterDetail/MasterDetail";
import OtherMasters from "../components/OtherMasters/OtherMasters";
import masterData from "../content.json";
import { useParams } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import MasterPortfolioSlider from "../components/MasterPortfolioSlider/MasterPortfolioSlider";

const MasterPage = () => {
  const { id } = useParams();
  const currentMaster = masterData.masters.cards.find(
    (master) => master.id === Number(id)
  );
  console.log("ID from URL:", id);

  return (
    <div>
      <Header />
      <MasterDetail master={currentMaster} />
      <MasterPortfolioSlider portfolio={currentMaster.portfolio} />
      <OtherMasters masters={masterData.masters.cards} />
      <Footer />
    </div>
  );
};

export default MasterPage;
