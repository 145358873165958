import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from "react";
import {Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import OpenRegister from "./pages/OpenRegister";
import NotFound from "./pages/NotFound";
import Redirect from "./components/Redirect/Redirect";
import MenuBar from './pages/MenuBar';
import MasterPage from './pages/MasterPage';

function App() {
    return(
     <>
         <Routes>
             <Route path={'/'} element={<Home/> } />
             <Route path={'/opening'} element={<OpenRegister/> } />
             <Route path={'/4u'}  element={<Redirect/>} />
             <Route path={'*'} element={<NotFound/> } />
             <Route path={'/vkusno'} element={<MenuBar/>}/>
             <Route path={'/master/:id'} element={<MasterPage/>}/>
         </Routes>
     </>
    )
}

export default App;