import React from "react";
import "./MasterDetail.css";

const MasterDetail = ({ master }) => {
  const redirect_v2 = (link) => {
    window.location =
      "https://n1142833.yclients.com/company/1052557/personal/select-master" +
      link;
  };

  return (
    <div className="master-detail">
      <div className="master-detail__img-container">
        <img
          src={master.img_url}
          alt={master.title}
          className="master-detail__img"
        />
      </div>
      <div className="master-detail__info">
        <h1 className="master-detail__title">{master.title}</h1>
        <p className="master-detail__description">{master.description}</p>
        <div className="master-detail__details-container">
          <div className="master-detail__details">
            <div className="master-detail__position">
              <h3>Должность:</h3>
              <p>{master.position}</p>
            </div>
            <div className="master-detail__experience">
              <h3>Стаж:</h3>
              <p>{master.experience}</p>
            </div>

            <button
              className="master-detail__button"
              onClick={() => {
                redirect_v2(master.link);
              }}
            >
              Записаться
            </button>
            
          </div>
          <div className="master-detail__services">
              <p><strong>Услуги:</strong></p>
              <ul>
                {master.services.map((service, index) => (
                  <li key={index}>{service}</li>
                ))}
              </ul>
            </div>
        </div>
        
      </div>
      
    </div>
  );
};

export default MasterDetail;
